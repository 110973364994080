<template>
	<v-container fluid max-width="60%">
		<v-dialog max-width="640px" v-model="dialog.show">
			<v-card title="Confirmation de l'envoie">
				<v-card-text>
					<v-container fluid>
						<v-row>
							{{ message }}
						</v-row>
						<v-row>
							Votre message sera envoyé à&nbsp;: {{ this.total_peoples }} personne(s).
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn @click="send_message()" color="purple" :disabled="dialog.in_progress" size="large" :text="dialog.btn_text" variant="flat" />
					<v-spacer />
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row class="marge-bottom">
			<v-alert closable :color="alert.color" :text="alert.message" :type="alert.type" v-model="alert.message" />
		</v-row>
		<v-row class="marge-top">
			<v-textarea counter label="Message" maxlength="160" variant="outlined" v-model="message" />
		</v-row>
		<v-row class="marge-bottom">
			<v-spacer />
			<v-btn @click="show_confirmation()" text="Envoyer" />
			<v-spacer />
		</v-row>
		<v-row class="marge-bottom marge-top">
			Vous êtes en manque d'imagination&nbsp;? Vous avez des doutes sur comment communiquer efficacement&nbsp;? Pas d'inquiétude, nous vous avons rédigé un guide de communication, plein d'astuces et de conseils pour vous aider dans vos démarches commerciales.
		</v-row>
		<v-row class="marge-top">
			<v-spacer />
			<v-btn>
				<a href="/guide.pdf">Accéder au guide de communication</a>
			</v-btn>
			<v-spacer />
		</v-row>
	</v-container>
</template>

<style scoped>
.marge-bottom {
	margin-bottom: 0.5em;
}
.marge-top {
	margin-top: 0.5em;
}
</style>

<script>
	import Api from '@/Api.js'

	export default {
		data() {
			return {
				alert: {
					color: null,
					message: null,
					show: false,
					type: null
				},
				dialog: {
					btn_text: "Envoyer",
					in_progress: false,
					show: false,
					total: 0
				},
				message: null
			}
		},
		methods: {
			send_message() {
				this.dialog.btn_text = "Envoie en cours..."
				this.dialog.in_progress = true

				let api = Api.get_instance()
				api.do_send_message({'message': this.message})
					.then(() => {
						this.message = null
						this.show_success("Message envoyé")
					})
					.catch(() => {
						this.show_error("Erreur lors de la programmation de l'envoie du sms")
					})
					.finally(() => {
						this.dialog.btn_text = "Envoyer"
						this.dialog.in_progress = false
						this.dialog.show = false
					})
			},
			show_confirmation() {
				if (this.message == null)
					return

				this.dialog.btn_text = 'Envoyer'
				this.dialog.in_progress = false
				this.dialog.show = true
			},
			show_error(msg) {
				this.alert.color = 'red'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'error'
			},
			show_success(msg) {
				this.alert.color = 'green'
				this.alert.message = msg
				this.alert.show = true
				this.alert.type = 'success'
			}
		},
		name: 'SmsMessage',
		props: {
			total_peoples: Number
		}
	}
</script>

